import React from "react";

export const Da = (props) => {
  const dict = {
    deactivate_account: "Deactivate Account for LetBabyTalk",
    we_are_sorry:
      "We’re sorry to see you go. Please let us know if there’s anything we can improve. If you still wish to deactivate your account, here are the steps to do so.",
    da1: "1. Sign in to the apps.",
    da2: "2. Go to Settings -> Account.",
    da3: "3. Click on Deactivate Account below Logout button.",
  };
  return (
    <div className="page">
      <h1>{dict?.deactivate_account}</h1>
      <p>{dict?.we_are_sorry}</p>

      <h3>{dict?.da1}</h3>
      <h3>{dict?.da2}</h3>
      <img src="img/deactivate-home.png" height="500rpx"></img>
      <img src="img/deactivate-settings.png" height="500rpx"></img>
      <h3>{dict?.da3}</h3>
      <img src="img/deactivate-account.png" height="500rpx"></img>
    </div>
  );
};
