import React from "react";

export const Tnc = (props) => {
  const dict = {
    terms_and_conditions: "Terms and Conditions for LetBabyTalk",
    welcome_to_leybabytalk: "Welcome to LetBabyTalk!",
    tnc0: "These Terms and Conditions govern your use of our mobile application and its associated services. By accessing or using the app, you agree to be bound by these Terms. If you disagree with any part of the terms, please do not use our Application.",
    tnc1: "1. Services Offered",
    tnc1c:
      "LetBabyTalk allows you to record your baby's crying sounds and provides predictions on why your baby might be crying. These predictions are not 100% accurate and are to be used as a guide only. If your child is experiencing distress or health issues, please consult a healthcare professional.",
    tnc2: "2. Account Registration",
    tnc2c:
      "To access certain features of the app, you may either sign up using your email address or phone number, or use the app in guest mode. Guest mode usage is limited, and personalization features require an account.",
    tnc3: "3. User Responsibilities",
    tnc3c:
      "When using LetBabyTalk, you must provide accurate information about your baby, including but not limited to their name, date of birth, and location, for personalized service. It's your responsibility to keep this information up to date.",
    tnc4: "4. Prohibited Uses",
    tnc4c:
      "You may not use our app for any illegal or unauthorized purpose. Furthermore, you may not, in the use of the service, violate any laws in your jurisdiction.",
    tnc5: "5. Changes to Terms",
    tnc5c:
      "We reserve the right to modify or replace these Terms at any time at our discretion. By continuing to access or use our app after those revisions become effective, you agree to be bound by the revised terms.",
  };
  return (
    <div className="page">
      <h1>{dict?.terms_and_conditions}</h1>
      <h2>{dict?.welcome_to_letbabytalk}</h2>
      <p>{dict?.tnc0}</p>

      <h3>{dict?.tnc1}</h3>
      <p>{dict?.tnc1c}</p>

      <h3>{dict?.tnc2}</h3>
      <p>{dict?.tnc2c}</p>

      <h3>{dict?.tnc3}</h3>
      <p>{dict?.tnc3c}</p>

      <h3>{dict?.tnc4}</h3>
      <p>{dict?.tnc4c}</p>

      <h3>{dict?.tnc5}</h3>
      <p>{dict?.tnc5c}</p>
    </div>
  );
};
