import React from "react";

export const Pp = (props) => {
  const dict = {
    privacy_policy_letbabytalk: "Privacy Policy",
    your_privacy: "Your Privacy",
    pp0: "Your privacy is important to us. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our mobile application. Please read this privacy policy carefully.",
    pp1: "1. Collection of Your Information",
    pp1c: "We may collect information about you in a variety of ways. The information we may collect via the Application depends on the content and materials you use, and includes:",
    pp1c_1: "Personal Data:",
    pp1c_1c:
      "Personally identifiable information, such as your name, email address, and phone number, additional details about your baby including their date of birth and location.",
    pp1c_2: "Derivative Data:",
    pp1c_2c:
      "Information our servers automatically collect when you access the app, such as your IP address and app version.",
    pp2: "2. Use of Your Information",
    pp2c: "Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the app to:",
    pp2c_1: "Create and manage your account.",
    pp2c_2:
      "Generate a personal profile about you to make future visits to the app more personalized.",
    pp2c_3: "Increase the efficiency and operation of the app.",
    pp2c_4:
      "Monitor and analyze usage and trends to improve your experience with the app.",
    pp3: "3. Disclosure of Your Information",
    pp3c: "We will not share your information with any third parties except as necessary to provide you with the services offered by us or to comply with the law.",
    pp4: "4. Security of Your Information",
    pp4c: "We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.",
    pp5: "5. Contact Us",
    pp5c: "If you have any questions or comments about these Terms and Conditions or the Privacy Policy, please contact us at: lettingbabytalk@gmail.com.",
  };
  return (
    <div className="page">
      <h1>{dict?.privacy_policy_letbabytalk}</h1>
      <p>{dict?.pp0}</p>

      <h3>{dict?.pp1}</h3>
      {/* <p>{dict?.pp1c}</p> */}
      <ul>
        <li>
          <strong>{dict?.pp1c_1}</strong> {dict?.pp1c_1c}
        </li>
        <li>
          <strong>{dict?.pp1c_2}</strong> {dict?.pp1c_2c}
        </li>
      </ul>

      <h3>{dict?.pp2}</h3>
      <p>{dict?.pp2c}</p>
      <ul>
        <li>{dict?.pp2c_1}</li>
        <li>{dict?.pp2c_2}</li>
        <li>{dict?.pp2c_3}</li>
        <li>{dict?.pp2c_4}</li>
      </ul>

      <h3>{dict?.pp3}</h3>
      <p>{dict?.pp3c}</p>

      <h3>{dict?.pp4}</h3>
      <p>{dict?.pp4c}</p>

      <h3>{dict?.pp5}</h3>
      <p>{dict?.pp5c}</p>
    </div>
  );
};
